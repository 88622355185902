/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { SxProps, Theme } from '@mui/material';

export const orderItemTableItemDetailsImageMiniSX: SxProps<Theme> = (theme) => ({
	maxWidth: theme.spacing(8),
	maxHeight: theme.spacing(8),
});
