/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { SxProps } from '@mui/material';
/**
 * @deprecated no longer maintained -- DO NOT USE
 */
export const productDetailsQuantitySX: SxProps = {
	maxWidth: '20ch',
};
